import React from 'react'
import {renderNothing, branch} from 'recompose'
import {Text, Flex, Box} from 'components'
import Participant from './Participant'
import useCustomTranslation from 'lib/customT'
import TransWrapper from 'lib/transWrapper'

const ParticipantGroup = ({name, color, participants}) => {
  const t = useCustomTranslation()

  return (
    <Flex flexDirection="column">
      <Box p={2}>
        <Text secondary>{t(name)}</Text>
      </Box>

      {participants.map((participant) => (
        <Flex p={2} key={participant.id} alignItems="center">
          <Participant
            color={color}
            style={{backgroundImage: `url('${participant.headerImage}')`}}
          />
          {participant.firstName} {participant.surname}{' '}
          {participant.isInactiveUser && t('(Får mails)')}
        </Flex>
      ))}
    </Flex>
  )
}

const enhancer = branch(
  ({participants}) => !participants || participants.length === 0,
  renderNothing
)

export default enhancer(ParticipantGroup)
